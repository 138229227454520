import React, {useEffect, useState} from "react"
import { graphql } from "gatsby"
import styled from 'styled-components';

import Button from '../components/Button';
import {log} from './../utilities/measure.js';

import {getParamsFromUrl} from '../components/Card/QR/qrUtilities';
import {calculateAnswer} from '../components/GameMaker/utilities.js';
import { getExample, renderData} from '../components/GameMaker/display.js';
import Share from '../components/Share/index';
import AnimatedPageWrapper from '../components/AnimatedPageWrapper/index';

import SEO from './../components/Seo/index';

import './../components/main.css';

import { useLocation } from '@reach/router';

const getAnswer = (query) => {
    
    log(`medication math getAnswer query`, query);
    let params = query?getParamsFromUrl(query):null;
    log(`medication math getAnswer ${query} params`, params);
    //save information in storage
    if(params && params.type){
        localStorage.setItem(params.type, JSON.stringify(params));
    }
    //get stock and question out of storage, if both present, calculate
    let stock = localStorage.getItem('stock');
    if(stock) stock = JSON.parse(stock);
    let question = localStorage.getItem('question');
    if(question) question = JSON.parse(question);
    return {stock, question}
}
const AnswerPage = (props) => {
  const [error, setError] = useState(null);
  const [stock, setStock] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [question, setQuestion] = useState(null);
  let location = useLocation();
  
  useEffect(()=>{
    try{
        let {question, stock} = getAnswer(location.search);
        setStock(stock);
        setQuestion(question);
        setAnswer(calculateAnswer(question, stock));
        setError(null);
    }catch(e){
        log(`medication math calculate answer error e`, e);
        setError(e.message);
    }
  }, [])
  const handleClearData = () => {
    localStorage.setItem('question', null);
    localStorage.setItem('stock', null);
    setStock(null);
    setQuestion(null);
    setAnswer(null);
    setError(null);
  }

  const renderAnswer = (answer) => {
    if(answer && answer.unit){
        return <div><h3 className="inline">Answer: </h3><p className="inline">{answer.quantity} {answer.unit}</p></div>;
    }else if(answer && answer.error){
        return <div><h3 className="inline">Answer: </h3><p className="inline">{answer.error}</p></div>;
    }
    return null;
  }
  const renderWorking = (question, stock) => {
      if(question && stock){
          return getExample(question.question, question, stock);
      }
      return <div>
        <h3>Working:</h3>
        <p>Both question and stock are required to calculate a working example</p>
      </div>; 
  }

  return  <AnimatedPageWrapper key="answer-page" id="answer-page">
        <AnswerWrapper key="answer">
                <Share siteInformation={props.data.site.siteMetadata} />
                <SEO
                  title={"Medication Math Answer Page"}
                  description={"the answer page of medication math. Cards QR codes can be scanned to calculate answers."}
                  pathname={props.location.pathname}
                />
              {renderData(stock, question)}
                <div className="btn-clear-data-wrapper">
                <Button onInput={handleClearData}>Clear Data</Button>
                </div>
                {error && <div><h5 className="inline">Error: </h5><p className="inline">{error}</p></div>}
                <div className="answer-info">
                    {renderAnswer(answer)}
                </div>
                {renderWorking(question, stock)}
          </AnswerWrapper>
  </AnimatedPageWrapper>
}
export default AnswerPage;

export const query = graphql`
  query AnswerPage{
    site {
      ...SiteInformation
    }
    file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
  }
`
const AnswerWrapper = styled.section`
    padding: 0px 10px;
    max-width: ${props => props.theme.widthL};
    margin:auto;
    background-color: ${props => props.theme.bg};
    .answer-info{
        margin-top:1em;
        margin-bottom: 1em;
    }
    .btn-clear-data-wrapper{
        text-align: center;
    }
`
const DataWrapper = styled.div`
    display: flex; 
    flex-direction: row;
    justify-content:center;
    flex-wrap: wrap;
    .card-data{
        margin-top: 20px;
        border-bottom: 2px solid black;
        padding: 1em;
    }

`